import React from "react";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import AdminFooter from "./AdminFooter";
import PackagesReport from "./packages/PackagesReport";

const PackagesPage = () => {
  return (
    <>
      <AdminHeader />
      <AdminSubHeader
        pageTitle={"Packages Report"}
        subTitle={"PKG Activation-Report"}
      />
      <PackagesReport />
      <AdminFooter />
    </>
  );
};

export default PackagesPage;
