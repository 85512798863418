import React from "react";
import logo from "../../assets/img/logo/logo.png";

const AdminFooter = () => {
  return (
    <div>
      {/* footer section start hare  */}

      <footer className="footer-wrapper footer-1">
        <div className="footer-bottom-wrapper">
          <div className="container">
            <div className="footer-bottom-content d-md-flex justify-content-between">
              <div
                className="site-copyright wow fadeInUp"
                data-wow-delay=".2"
                data-wow-duration="1s"
              >
                <a href="/">
                  <img src={logo} alt="TradePro" />
                </a>
                &copy; 2024 <a href="/">LillyBot</a> All Rights Reserved.
              </div>
              <div
                className="social-links mt-4 mt-md-0 wow fadeInUp"
                data-wow-delay=".3"
                data-wow-duration="1s"
              >
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-telegram-plane"></i>
                </a>
                <a href="#">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer section end hare */}
    </div>
  );
};

export default AdminFooter;
