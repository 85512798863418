import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import icon1 from "../../assets/img/price/icon/1.png";
import styles from "./customStyles.css";

import { getAllPackages } from "../../redux/actions/package";
const Price = ({ getAllPackages, allPackages }) => {
  useEffect(() => {
    getAllPackages();
  }, [getAllPackages]);
  return (
    <div>
      {/* <!-- price section start hare --> */}

      <section className="package-pricing-wrapper fix section-bg-4 section-padding">
        <div className="container">
          <div className="col-lg-8 ps-xl-5 pe-xl-5 offset-lg-2 text-center">
            <div className="block-contents">
              <div className="section-title">
                <h2 className="wow fadeInUp">
                  Tailored Plans to Fit Your Financial Needs
                </h2>
                <p className="wow fadeInUp pt-4" data-wow-delay=".3s">
                  LillyBot is advanced AI based auto crypto trading bot
                  spicially developed for LillyBot members.
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            {allPackages &&
              allPackages.map((pkg) => {
                // Split the features string into an array
                // const features = pkg.pkg_info ? pkg.pkg_info.split("\r\n") : [];
                const features = pkg.pkg_info.split("\n").filter(Boolean);

                return (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6"
                    key={pkg.pkg_name}
                  >
                    <div
                      className="single-pricing-package single-pricing-package--style2 wow fadeInUp"
                      data-wow-duration="1.1s"
                    >
                      <div className={styles.priceListRow}>
                        <div className="pricing-head">
                          <div className="pricing-name">
                            <h4>{pkg.pkg_name}</h4>
                          </div>
                          <div className="pricing-vale d-flex">
                            <h3>
                              $<span>{pkg.pkg_amount}</span>
                            </h3>
                            <p>/ {pkg.growth_per}</p>
                          </div>
                        </div>
                        <div className="features-list">
                          <ul>
                            {features.map((feature, index) => (
                              <li key={index}>
                                <img src={icon1} alt="right-arrow" />
                                {feature}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="buy-package-btn mt-4">
                        <a
                          href="/signup"
                          className="custom-btn custom-btn--price"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div
                className="single-pricing-package single-pricing-package--style2 wow fadeInUp"
                data-wow-duration="1.1s"
              >
                <div className={styles.threeRows}>
                  <div className="pricing-head">
                    <div className="pricing-name">
                      <h4>Basic Plan</h4>
                    </div>
                    <div className="pricing-vale d-flex">
                      <h3>
                        $<span>100</span>
                      </h3>
                      <p>/ 0.5% Per Day</p>
                    </div>
                  </div>
                  <div className="features-list">
                    <ul>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        2X Profit
                      </li>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        Daily Earning Capping of $10
                      </li>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        Recurring Income on Team Capital
                      </li>
                    </ul>
                  </div>
                  <div className="buy-package-btn mt-4">
                    <a href="/signup" className="custom-btn custom-btn--price">
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-xl-3 col-lg-4 col-md-6">
              <div
                className="single-pricing-package single-pricing-package--style2 wow fadeInUp"
                data-wow-duration="1.1s"
              >
                <div className={styles.threeRows}>
                  <div className="pricing-head">
                    <div className="pricing-name">
                      <h4>Starter Plan</h4>
                    </div>
                    <div className="pricing-vale d-flex">
                      <h3>
                        $<span>500</span>
                      </h3>
                      <p>/ 0.75 Per Day</p>
                    </div>
                  </div>
                  <div className="features-list">
                    <ul>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        2X Profit
                      </li>
                      <li>
                        <img src={icon1} alt="icon" />
                        Daily Earning Capping of $25
                      </li>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        Recurring Income on Team Capital
                      </li>
                    </ul>
                  </div>
                  <div className="buy-package-btn mt-4">
                    <a href="/signup" className="custom-btn custom-btn--price">
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div
                className="single-pricing-package single-pricing-package--style2 single-pricing-package--active2  wow fadeInUp"
                data-wow-duration="1.1s"
              >
                <span className="active-bage active-bage--style2">Popular</span>
                <div className={styles.threeRows}>
                  <div className="pricing-head">
                    <div className="pricing-name">
                      <h4>Advanced Plan</h4>
                    </div>
                    <div className="pricing-vale d-flex">
                      <h3>
                        $<span>1000</span>
                      </h3>
                      <p>/ 1 % Per Day</p>
                    </div>
                  </div>
                  <div className="features-list">
                    <ul>
                      <li>
                        <img src={icon1} alt="icon" />
                        Daily Earning Capping of $25
                      </li>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        Recurring Income on Team Capital
                      </li>
                      <li>
                        <img src={icon1} alt="icon" />
                        Daily Earning Capping of $50
                      </li>
                      <li>
                        <img src={icon1} alt="icon" />
                        24/7 Support.
                      </li>
                    </ul>
                  </div>
                  <div className="buy-package-btn mt-4">
                    <a
                      href="/signup"
                      className="custom-btn custom-btn--price custom-btn--price--active"
                    >
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div
                className="single-pricing-package single-pricing-package--style2 wow fadeInUp"
                data-wow-duration="1.1s"
              >
                <div className={styles.threeRows}>
                  <div className="pricing-head">
                    <div className="pricing-name">
                      <h4>Premium Plan</h4>
                    </div>
                    <div className="pricing-vale d-flex">
                      <h3>
                        $<span>5000</span>
                      </h3>
                      <p>/ 1.25 % Per Day</p>
                    </div>
                  </div>
                  <div className="features-list">
                    <ul>
                      <li>
                        <img src={icon1} alt="icon" />
                        3X Profit
                      </li>
                      <li>
                        <img src={icon1} alt="icon" />
                        Daily Earning Capping of $25
                      </li>
                      <li>
                        <img src={icon1} alt="right-arrow" />
                        Recurring Income on Team Capital
                      </li>
                      <li>
                        <img src={icon1} alt="icon" />
                        Multi-Asset Trading
                      </li>
                      <li>
                        <img src={icon1} alt="icon" />
                        24/7 Support.
                      </li>
                    </ul>
                  </div>
                  <div className="buy-package-btn mt-4">
                    <a
                      href="contact.html"
                      className="custom-btn custom-btn--price "
                    >
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* <!-- price section end hare --> */}
    </div>
  );
};

Price.propTypes = {
  getAllPackages: PropTypes.func.isRequired,
  allPackages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  allPackages: state.packages.allPackages,
});

export default connect(mapStateToProps, {
  getAllPackages,
})(Price);
