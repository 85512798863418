import React, { useState, useMemo } from "react";
import { ArrowRight, ArrowLeft } from "react-bootstrap-icons";
import noDataImg from "../../../assets/img/banner/no-data.png";

const TeamReport = ({ teamData }) => {
  const [isTeamInfo, setTeamInfo] = useState(false);
  const [level, setLevel] = useState(0);
  const [isTeamInfoData, setTeamInfoData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: "ascending",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const levelData = useMemo(() => {
    return (
      teamData?.teamLevelReport?.map((element) => ({
        ...element,
        total_members: Array.isArray(element.users) ? element.users.length : 0,
      })) || []
    );
  }, [teamData]);

  const levelInfo = (level) => {
    const teamInfo = levelData.find((fildata) => fildata.level === level);
    setLevel(level);
    setTeamInfoData(teamInfo ? teamInfo.users : []);
    setTeamInfo(true);
    setSearchTerm("");
    setSortConfig({ key: "", direction: "ascending" });
  };

  const handleFilter = (event) => setSearchTerm(event.target.value);

  const handleSort = (key) => {
    setSortConfig((prevState) => ({
      key,
      direction:
        prevState.key === key && prevState.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const filteredData = useMemo(() => {
    let filtered = isTeamInfo ? isTeamInfoData : levelData;

    if (searchTerm) {
      filtered = filtered.filter((val) =>
        [
          "level",
          "total_members",
          "eligibility",
          "eligibility_required",
          "total_business",
        ].some((field) =>
          val[field]
            ?.toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        )
      );
    }

    if (sortConfig.key) {
      filtered = [...filtered].sort((a, b) => {
        const aKey = a[sortConfig.key] || "";
        const bKey = b[sortConfig.key] || "";

        return aKey < bKey
          ? sortConfig.direction === "ascending"
            ? -1
            : 1
          : aKey > bKey
          ? sortConfig.direction === "ascending"
            ? 1
            : -1
          : 0;
      });
    }

    return filtered;
  }, [isTeamInfo, isTeamInfoData, levelData, searchTerm, sortConfig]);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return filteredData.slice(start, start + itemsPerPage);
  }, [filteredData, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const renderTable = (data, columns, showDetailsButton) => (
    <div className="table-responsive">
      <table className="table table-dark table-striped">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                scope="col"
                onClick={col.sortable ? () => handleSort(col.key) : undefined}
              >
                {col.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              {/* <th scope="row">
                {index + 1 + (currentPage - 1) * itemsPerPage}
              </th> */}
              {showDetailsButton && (
                <td>
                  <button
                    className="btn btn-info ms-2"
                    onClick={() => levelInfo(val.level)}
                    style={{ cursor: "pointer" }}
                  >
                    Details
                  </button>
                </td>
              )}
              {columns.slice(showDetailsButton ? 1 : 0).map((col, i) => (
                <td key={i}>{val[col.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderPagination = () => (
    <div className="d-flex justify-content-between p-3">
      <button
        className="btn btn-primary"
        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        disabled={currentPage === 1}
      >
        <ArrowLeft />
      </button>
      <span>
        Page {currentPage} of {totalPages}
      </span>
      <button
        className="btn btn-primary"
        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        disabled={currentPage === totalPages}
      >
        <ArrowRight />
      </button>
    </div>
  );

  const teamLevelColumns = [
    { key: "button", label: "Details", sortable: true },
    { key: "level", label: "Level", sortable: true },
    { key: "total_members", label: "Total Users", sortable: true },
    { key: "eligibility", label: "Eligibility", sortable: true },
    { key: "total_business", label: "Total Business", sortable: true },
    {
      key: "eligibility_required",
      label: "Eligibility Required",
      sortable: true,
    },
  ];

  const userColumns = [
    { key: "referral_id", label: "User ID", sortable: true },
    { key: "total_business", label: "Total Pkg Amount", sortable: true },
    { key: "user_created_on", label: "Joining Date", sortable: true },
    {
      key: "first_package_activation_date",
      label: "Activation Date",
      sortable: true,
    },
    // { key: "elegibility_diffrence", label: "Eligibility Required", sortable: true },
  ];
  // console.log(paginatedData);

  return (
    <div>
      <div className="section-padding-medium">
        <div className="card mb-3">
          <div className="card-header">
            <div className="card-title bg-dark text-light text-center rounded p-3">
              <h5 className="m-0 me-2">
                <span className="me-2">
                  {!isTeamInfo ? "Team Level Report" : `${level} Level Info`}
                </span>
                {isTeamInfo && (
                  <button
                    className="btn btn-success ms-2 text-white"
                    onClick={() => {
                      setTeamInfo(false);
                      setSearchTerm("");
                      setCurrentPage(1);
                    }}
                  >
                    Back To Team Report
                  </button>
                )}
              </h5>
            </div>
          </div>

          <div className="card p-3">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleFilter}
              className="mb-3 form-control"
            />
            {paginatedData.length > 0 ? (
              <>
                {!isTeamInfo
                  ? renderTable(paginatedData, teamLevelColumns, true)
                  : renderTable(paginatedData, userColumns, false)}
                {renderPagination()}
              </>
            ) : (
              <div className="no-data text-center rounded rounded-bottom">
                <img src={noDataImg} alt="No Data" className="img-fluid" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamReport;
