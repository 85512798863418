import React from "react";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import AdminFooter from "./AdminFooter";
import DailyGrowthReport from "./revenue/DailyGrowthReport";

const RevenuePage = () => {
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader
        pageTitle={"Revenue Reports"}
        subTitle={"Daily Growth Report"}
      />
      <DailyGrowthReport />
      <AdminFooter />
    </div>
  );
};

export default RevenuePage;
