import React from "react";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import AdminFooter from "./AdminFooter";
import UsersReport from "./users/UsersReport";

const UsersPage = () => {
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader
        pageTitle={"Users Report"}
        subTitle={"Users Joining Report"}
      />
      <UsersReport />
      <AdminFooter />
    </div>
  );
};

export default UsersPage;
