import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/logo2.png";

const AdminHeader = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/admin-login";
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      {/* <!-- header section start here --> */}
      <header className="header-1">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-2 col-sm-5 col-md-4 col-6">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Lilly Bot Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-10 p-lg-0 d-none d-lg-flex align-items-center justify-content-end">
              <div className="menu-wrap">
                <div className="main-menu">
                  <ul>
                    <li className="menu-item">
                      <Link to="/control-panel">Admin Dashboard</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/transactions">Transaction Report</Link>
                      <ul>
                        <li className="sub-menu">
                          <Link to="/deposit-report">Deposit Report</Link>
                        </li>
                        <li className="sub-menu">
                          <Link to="/withdrawal-report">Withdrawal Report</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <Link to="/packages-report">
                        Package Activation Report
                      </Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/users-report">User Report</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link to="/revenue-report">User Revenue Report</Link>
                      <ul class="sub-menu">
                        <li>
                          <Link to="/direct-ref-income">
                            Direct Ref Income Report
                          </Link>
                        </li>
                        <li>
                          <Link to="/generation-income">
                            Generation Income Report
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header-right-element text-white">
                <button
                  type="button"
                  className="custom-btn custom-btn--style-2"
                  onClick={logout}
                >
                  Logout
                </button>
              </div>
            </div>
            <div className="d-block d-lg-none col-sm-1 col-md-8 col-6">
              <div className="mobile-nav-wrap">
                <div id="hamburger" onClick={toggleMobileMenu}>
                  <i className="fal fa-bars"></i>
                </div>
                {/* <!-- mobile menu - responsive menu --> */}
                <div className={`mobile-nav ${isMobileMenuOpen ? "open" : ""}`}>
                  <button
                    type="button"
                    className="close-nav"
                    onClick={toggleMobileMenu}
                  >
                    <i className="fal fa-times-circle"></i>
                  </button>
                  <nav className="sidebar-nav">
                    <ul className="metismenu" id="mobile-menu">
                      <li className="menu-item">
                        <Link to="/user">Admin Dashboard</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/packages">Transaction Report</Link>
                        <ul>
                          <li className="sub-menu">Deposit Report</li>
                          <li className="sub-menu">Withdrawal Report</li>
                          <li className="sub-menu">Wallet Report</li>
                        </ul>
                      </li>
                      <li className="menu-item">
                        <Link to="/wallet">Package Activation Report</Link>
                      </li>
                      <li className="menu-item">
                        <Link to="/team">User Report</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/revenue">User Revenue Report</Link>
                        <ul class="sub-menu">
                          <li>
                            <Link to="/revenue/passive-income">
                              Growth Income Report
                            </Link>
                          </li>
                          <li>
                            <Link to="/revenue/passive-income">
                              Direct Ref Income Report
                            </Link>
                          </li>
                          <li>
                            <Link to="/revenue/passive-income">
                              Generation Income Report
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <button
                      type="button"
                      className="custom-btn"
                      onClick={logout}
                    >
                      Logout
                    </button>
                  </nav>
                </div>
              </div>
              <div
                className={`overlay ${isMobileMenuOpen ? "open" : ""}`}
                onClick={toggleMobileMenu}
              ></div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- header section end here --> */}
    </div>
  );
};

export default AdminHeader;
