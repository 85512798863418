import React from "react";

const AdminSubHeader = ({ pageTitle, subTitle }) => {
  return (
    <div>
      <div className="admin-banner-wrap  text-capitalize">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 ps-xl-0 pe-xl-0 offset-xl-3 col-lg-8 offset-lg-2 text-center text-white">
              <div className="page-heading">
                <h2>{pageTitle}</h2>
                <nav aria-label="breadcrumb">
                  <ul className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a href="/user">{pageTitle}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {subTitle}
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="breadcrumb-wrapper">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/user">{subTitle}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {pageTitle}
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {subTitle}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSubHeader;
