import React from "react";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import AdminFooter from "./AdminFooter";
import WalletReport from "./transactions/WalletReport";

const TransactionsPage = () => {
  return (
    <>
      <AdminHeader />
      <AdminSubHeader pageTitle={"Transactions"} subTitle={"Wallet-Report"} />
      <WalletReport />
      <AdminFooter />
    </>
  );
};

export default TransactionsPage;
