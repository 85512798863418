import React from "react";
import AdminHeader from "./components/AdminHeader";
import AdminSubHeader from "./components/AdminSubHeader";
import AdminFooter from "./components/AdminFooter";

import signin from "../assets/img/account/accont.png";
import AdminSignin from "./components/AdminSignin";

const AdminLogin = () => {
  return (
    <div>
      <AdminSignin />
      <AdminFooter />
    </div>
  );
};

export default AdminLogin;
