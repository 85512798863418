import React from "react";

import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import userCard3 from "../../assets/img/user/card/green/03.png";
import userCard4 from "../../assets/img/user/card/green/04.png";

import styles from "../admin.module.css";

const AdminData = () => {
  return (
    <section className="user-ditails-wrapper fix section-bg-5 section-padding-medium">
      <div className="container mb-4">
        <div className="col-lg-8 ps-xl-5 pe-xl-5 offset-lg-2 text-center">
          <div className="block-contents">
            <div className="section-title">
              <h3 className="wow fadeInUp">
                Welcome Admin to LillyBot Control Panel
              </h3>
              <p className="wow fadeInUp pt-4" data-wow-delay=".3s">
                Here you shall find all site data in single snapshot.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper ">
                <div className="user-card-thumb">
                  <img src={userCard} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Todays Joining
                      </div>
                      <p>0</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>Total Users</div>
                      <p>0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper">
                <div className="user-card-thumb">
                  <img src={userCard2} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Todays Deposits
                      </div>
                      <p>$0</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Total Deposits
                      </div>
                      <p>$0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper">
                <div className="user-card-thumb">
                  <img src={userCard3} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Todays Activation
                      </div>
                      <p>$0</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Total Business
                      </div>
                      <p>$0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper">
                <div className="user-card-thumb">
                  <img src={userCard4} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Todays Withdrawal
                      </div>
                      <p>${0.0}</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Total Withdrawal
                      </div>
                      <p>${0.0}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4 section-padding-medium">
        <div className="row">
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper ">
                <div className="user-card-thumb">
                  <img src={userCard} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>Daily Payout</div>
                      <p>${"0.00"}</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Expected Payout
                      </div>
                      <p>${"0.00"}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper">
                <div className="user-card-thumb">
                  <img src={userCard2} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        PKG Activation
                      </div>
                      <p> Today 0</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        Total Active PKG
                      </div>
                      <p> All Time 0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper">
                <div className="user-card-thumb">
                  <img src={userCard2} alt="invest icon" />
                </div>
                <div className="user-card-content mt-2">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <div className={styles.fontMediumBold}>Active Users</div>
                      <p> Today 0</p>
                    </div>
                    <div className="col">
                      <div className={styles.fontMediumBold}>
                        All Time Active
                      </div>
                      <p> All Time 0</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6">
            <div
              className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".2s"
            >
              <div className="user-card-wrapper">
                <div className="user-card-thumb">
                  <img src={userCard3} alt="invest icon" />
                </div>
                <div className="user-card-content mt-4">
                  <div className={styles.twoColumns}>
                    <div className="col">
                      <button
                        className="btn btn-success"
                        onClick={() => (window.location.href = "#")}
                      >
                        Deposit
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-danger"
                        onClick={() => (window.location.href = "#")}
                      >
                        Withdraw
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminData;
