import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "../custom.module.css";

import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import userCard3 from "../../assets/img/user/card/green/03.png";
import userCard4 from "../../assets/img/user/card/green/04.png";
import DirectTeamReport from "./teamDataTables/DirectTeamReport";
import TeamReport from "./teamDataTables/TeamReport";

import { getWalletData } from "../../redux/actions/wallet";
import { getteamData } from "../../redux/actions/team";

const TeamData = ({ getWalletData, getteamData, walletData, teamData }) => {
  useEffect(() => {
    getWalletData();
    getteamData();
  }, [getWalletData, getteamData]);
  const [isDirectReport, setDirectReport] = useState(true);
  let totalInvestment = 0;
  if (walletData?.GrowthData?.updatedPackagesData) {
    const packgesData = walletData?.GrowthData?.updatedPackagesData;
    packgesData.forEach((element) => {
      totalInvestment += element.Package.pkg_amount;
    });
  }

  return (
    <div>
      <section className="user-ditails-wrapper fix section-bg-5 section-padding-medium">
        <div className="container ">
          <div className="row section-padding-medium">
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Investment
                        </div>
                        <p>${totalInvestment}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Earning
                        </div>
                        <p>
                          $
                          {(walletData?.GrowthData?.totalGrowth || 0.0).toFixed(
                            2
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Members
                        </div>
                        <p>{teamData?.directTeamCount || 0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Business
                        </div>
                        <p>${teamData?.directBusiness || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard3} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Members
                        </div>
                        <p>{teamData?.totalTeamCount || 0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Business
                        </div>
                        <p>${teamData?.grandTotalBusiness || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard4} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Direct Income
                        </div>
                        <p>${teamData?.directIncome || 0.0}</p>
                      </div>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Team Earning
                        </div>
                        <p>${teamData?.totalIncome || 0.0}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay=".2s"
        >
          <div className={styles.twoColumns}>
            <div className="text-center">
              <button
                className="btn btn-primary"
                onClick={() => setDirectReport(true)}
              >
                Direct Team Report
              </button>
            </div>
            <div className="text-center">
              <button
                className="btn btn-danger"
                onClick={() => setDirectReport(false)}
              >
                Generation Team Report
              </button>
            </div>
          </div>
          {isDirectReport ? (
            <DirectTeamReport teamData={teamData} />
          ) : (
            <TeamReport teamData={teamData} />
          )}
        </div>
      </section>
    </div>
  );
};

TeamData.propTypes = {
  getWalletData: PropTypes.func.isRequired,
  getteamData: PropTypes.func.isRequired,
  walletData: PropTypes.object.isRequired,
  teamData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  walletData: state.wallet.walletData,
  teamData: state.team.teamData,
});

export default connect(mapStateToProps, { getWalletData, getteamData })(
  TeamData
);
