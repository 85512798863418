import React, { useState, useEffect } from "react";
import { toast } from "react-toastify"; // Ensure toast is properly initialized
import { connect } from "react-redux";
import PropTypes from "prop-types";

import styles from "../custom.module.css";

import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import { getTRONAddress, withdrawRewards } from "../../redux/actions/wallet";
import { getWalletData } from "../../redux/actions/wallet";
import WalletReportTable from "./WalletReportTable";

const WalletFunctions = ({
  getTRONAddress,
  withdrawRewards,
  getWalletData,
  addressData,
  walletData,
}) => {
  const [formData, setFormData] = useState({ address: "", amount: 0 });
  const [otp, setOtp] = useState(null);
  const [isMail, setisMail] = useState(true);
  const [isRequest, setisRequest] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { address, amount } = formData;
  useEffect(() => {
    getWalletData();
  }, [getWalletData]);
  // console.log(walletData);
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleGenerateWallet = () => {
    // console.log("handleGenerateWallet");
    getTRONAddress();
  };
  const handleWithdrawal = (e) => {
    e.preventDefault();
    setisRequest(true);
    const data = { address, isMail, otp, amount };
    withdrawRewards(data, (success) => {
      if (success === "OTP_SENT") {
        setisMail(false);
      } else if (success === "SUCCESS") {
        setisMail(true);
        setFormData({ address: "", amount: 0 });
        setOtp(null);
      }
      setisRequest(false);
    });
  };
  useEffect(() => {
    let timer;
    if (isDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsDisabled(false);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isDisabled, countdown]);

  const handleCopy = () => {
    if (addressData && addressData.address) {
      navigator.clipboard.writeText(addressData.address);
      toast.success("Address copied to clipboard!");
    } else {
      toast.error("No address to copy!");
    }
  };

  return (
    <div>
      <section className="user-ditails-wrapper fix section-bg-5 section-padding-medium">
        <div className="container">
          <div className="row mb-4">
            <div className="col-xl-6 col-sm-12">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Total Deposit
                        </div>
                        <p>${walletData?.totalCredit || "0.00"}</p>
                      </div>
                      <div className="col mb-2">
                        <p className="m-3">
                          <button
                            className="btn btn-success"
                            data-bs-toggle="collapse"
                            href="#collapseDeposit"
                            onClick={handleGenerateWallet} // Correct function call syntax
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            Deposit
                          </button>
                        </p>
                        <div className={styles.smallFont}>
                          Get the deposit address for TRC20 USDT deposit via the
                          blockchain.
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="collapseDeposit">
                      <div className="card card-body d-flex flex-column align-items-center p-4 justify-content-center">
                        {addressData?.qrCode && (
                          <img
                            className="mb-3"
                            src={addressData.qrCode}
                            alt="qrCode"
                            height="150"
                            width="150"
                          />
                        )}
                        <div className="input-group pb-3">
                          <input
                            type="text"
                            value={addressData?.address || ""}
                            className="form-control text-center"
                            readOnly
                          />
                          <span
                            className="input-group-text"
                            onClick={handleCopy}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-copy"></i>
                          </span>
                        </div>
                        <div
                          className="alert alert-warning alert-dismissible p-3 mt-3"
                          role="alert"
                        >
                          * Please double check copied address while deposit to
                          avoid any loss due to transaction sent to the wrong
                          address.
                          <br />
                          <br />* Deposit Transaction is processed and confirmed
                          only after a minimum of 10 transaction confirmations
                          on the blockchain.
                          <button
                            type="button"
                            className="btn-close text-danger"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  <div className="user-card-thumb">
                    <img src={userCard2} alt="invest icon" />
                  </div>
                  <div className="user-card-content mt-2">
                    <div className={styles.twoColumns}>
                      <div className="col">
                        <div className={styles.fontMediumBold}>
                          Withdrawale Balance
                        </div>
                        <p>${walletData?.withBal || 0.0}</p>
                      </div>
                      <div className="col mb-2">
                        <p className="m-3">
                          <a
                            className="btn btn-danger"
                            data-bs-toggle="collapse"
                            href="#collapseWithdraw"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            Withdraw
                          </a>
                        </p>
                        <div className={styles.smallFont}>
                          In below form submit your correct TRC20 USDT addrerss
                          for withdrawal.
                        </div>
                      </div>
                    </div>
                    <div className="collapse" id="collapseWithdraw">
                      <div className="card card-body p-3">
                        <div className="form_container text-center">
                          <form onSubmit={handleWithdrawal}>
                            {isMail ? (
                              <div>
                                <input
                                  type="text"
                                  name="address"
                                  onChange={onChange}
                                  className="form-control mb-3"
                                  placeholder="Your USDT Wallet Address"
                                  required
                                />
                                <input
                                  type="number"
                                  className="form-control"
                                  name="amount"
                                  onChange={onChange}
                                  placeholder="Enter Amount"
                                  required
                                />
                              </div>
                            ) : (
                              <>
                                <div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="otp"
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="Enter OTP"
                                    required
                                  />
                                </div>
                                <div className="btn_box mt-3 pb-3 text-center">
                                  {isRequest ? (
                                    <button
                                      type="button"
                                      className="btn btn-medium btn-danger"
                                      disabled
                                    >
                                      Please Wait...
                                    </button>
                                  ) : isMail ? (
                                    <button
                                      type="submit"
                                      className="btn btn-medium btn-danger"
                                    >
                                      Submit Withdrawal
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-medium btn-danger"
                                    >
                                      Submit OTP
                                    </button>
                                  )}
                                </div>
                                <div
                                  className="alert alert-warning alert-dismissible"
                                  role="alert"
                                >
                                  * All withdrawal requests are processed within
                                  a few minutes. But in case of any server or
                                  technical issue, please wait for at least 24
                                  hours before raising an issue.
                                  <button
                                    type="button"
                                    className="btn-close text-danger"
                                    data-bs-dismiss="alert"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <WalletReportTable walletData={walletData} />
          </div>
        </div>
      </section>
    </div>
  );
};

WalletFunctions.propTypes = {
  getTRONAddress: PropTypes.func.isRequired,
  withdrawRewards: PropTypes.func.isRequired,
  getWalletData: PropTypes.func.isRequired,
  addressData: PropTypes.object.isRequired,
  walletData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  addressData: state.wallet.addressData,
  walletData: state.wallet.walletData,
});

export default connect(mapStateToProps, {
  getTRONAddress,
  withdrawRewards,
  getWalletData,
})(WalletFunctions);
