import React from "react";
import AdminHeader from "./components/AdminHeader";
import AdminSubHeader from "./components/AdminSubHeader";
import AdminFooter from "./components/AdminFooter";
import AdminData from "./components/AdminData";

const AdminIndex = () => {
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader
        pageTitle={"Admin-Dashboard"}
        subTitle={"Account Snapshot"}
      />
      <AdminData />
      <AdminFooter />
    </div>
  );
};

export default AdminIndex;
