import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styles from "../custom.module.css";
import { getActivateUserPackages } from "../../redux/actions/package";
import formatDate from "../../utils/formatDate";
import userCard from "../../assets/img/user/card/green/01.png";
import userCard2 from "../../assets/img/user/card/green/02.png";
import ChangePassword from "./ChangePassword";

import noActivePkgImg from "../../assets/img/banner/no-active-pkg.png";

const ProfileData = ({ getActivateUserPackages, activeUserPackages }) => {
  useEffect(() => {
    getActivateUserPackages();
  }, [getActivateUserPackages]);

  const [pkgName, setPkgName] = useState("");
  const [pkgAmount, setPkgAmount] = useState("");
  const [dailyGrowth, setdailyGrowth] = useState("");
  const [dailyCapping, setdailyCapping] = useState("");
  const [activationDate, setactivationDate] = useState("");

  useEffect(() => {
    const userActivePkg = activeUserPackages; // Safe access to the first element
    if (userActivePkg.length) {
      setPkgName(userActivePkg[userActivePkg.length - 1].Package.pkg_name);
      setPkgAmount(userActivePkg[userActivePkg.length - 1].Package.pkg_amount);
      setdailyGrowth(
        userActivePkg[userActivePkg.length - 1].Package.growth_per
      );
      setdailyCapping(
        userActivePkg[userActivePkg.length - 1].Package.per_day_income_capping
      );
      setactivationDate(
        formatDate(userActivePkg[userActivePkg.length - 1].created_on)
      );
    } else {
      // console.log("No active package found");
    }
  }, [activeUserPackages]);

  const [changePass, setchangePass] = useState(false);

  const showChangePass = () => {
    if (changePass) {
      setchangePass(false);
    } else {
      setchangePass(true);
    }
  };

  const user = useSelector((state) => state.auth.user);

  return (
    <div>
      <section className="user-ditails-wrapper fix section-bg-5 section-padding">
        <div className="container mb-5">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper ">
                  <div className="user-card-thumb">
                    <img src={userCard} alt="user card" />
                  </div>
                  <div className="user-card-content mt-2 text-start p-3">
                    <div className={styles.twoColumns}>
                      <div className={styles.smallFont}> User Name</div>
                      <div className={styles.smallFont}>{user?.name}</div>
                      <div className={styles.smallFont}> User ID</div>
                      <div className={styles.smallFont}>
                        {user?.referral_id}
                      </div>
                      <div className={styles.smallFont}> User Email</div>
                      <div className={styles.smallFont}>{user?.email}</div>
                      <div className={styles.smallFont}> Sponsor ID</div>
                      <div className={styles.smallFont}>
                        {user?.referred_by}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div
                className="single-user-ditails-card single-user-ditails-card--style2 mb-4 mb-xl-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
              >
                <div className="user-card-wrapper">
                  {pkgName.length > 0 ? (
                    <>
                      <div className="user-card-thumb">
                        <img src={userCard2} alt="user card" />
                      </div>
                      <div className="user-card-content mt-2">
                        <div className={styles.twoColumns}>
                          <div className={styles.smallFont}>
                            {" "}
                            User Active Package
                          </div>
                          <div className={styles.smallFont}>{pkgName}</div>
                          <div className={styles.smallFont}> Amount</div>
                          <div className={styles.smallFont}>${pkgAmount}</div>
                          <div className={styles.smallFont}> Daily Growth</div>
                          <div className={styles.smallFont}>{dailyGrowth}%</div>
                          {/* <div className={styles.smallFont}> Daily Capping</div>
                          <div className={styles.smallFont}>{dailyCapping}</div> */}
                          <div className={styles.smallFont}>
                            {" "}
                            Activation Date
                          </div>
                          <div className={styles.smallFont}>
                            {activationDate}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="no-data text-center rounded rounded-bottom">
                      <h2>No Active Package</h2>
                      <img
                        src={noActivePkgImg}
                        alt="No Data"
                        className="img-fluid no-pkg"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container mb-5 text-center wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay=".3s"
        >
          <div className="col-md-6 col-lg-12">
            <button className="btn btn-primary btn-md" onClick={showChangePass}>
              Change Password
            </button>
          </div>
        </div>
        {changePass ? <ChangePassword /> : ""}
      </section>
    </div>
  );
};

ProfileData.propTypes = {
  getActivateUserPackages: PropTypes.func.isRequired,
  activeUserPackages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  activeUserPackages: state.packages.activeUserPackages,
});

export default connect(mapStateToProps, { getActivateUserPackages })(
  ProfileData
);
